<template>
  <v-tour 
    :name="tourName" 
    :steps="steps" 
    :options="options" 
    :callbacks="callbacks" 
    v-if="tourName && steps">
    <template slot-scope="tour">
      <transition name="fade">
        <template v-for="(step, index) of tour.steps">
          <v-step
            v-if="tour.currentStep === index"
            :key="index"
            :step="step"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
            :highlight="tour.highlight"
            :enabled-buttons="tour.enabledButtons"
          >
            <!-- Custom Header -->
            <!-- <div slot="header" class="bg-info">
              hahaa
            </div> -->
            <!-- Custom Content -->
            <!-- <div slot="content" class="bg-primary">
              hihi
            </div> -->
            <!-- Custom Action -->
            <div slot="actions">
              <div class="v-step__buttons">
                <!-- Checkout Page START -->
                <template v-if="currentStep == steps.findIndex((el) => el.target == '#save-address-form')">
                  <button @click.prevent="skip" v-if="!isLast" class="v-step__button v-step__button-skip" :class="{'disabled' : !isButtonEnable('buttonSkip')}" :disabled="!isButtonEnable('buttonSkip')">{{ options.labels.buttonSkip }}</button>
                  <button @click.prevent="previousStep" v-if="!isFirst" class="v-step__button v-step__button-previous" :class="{'disabled' : !isButtonEnable('buttonPrevious')}" :disabled="!isButtonEnable('buttonPrevious')">{{ options.labels.buttonPrevious }}</button>
                  <button @click.prevent="nextStep" v-if="!isLast" class="v-step__button v-step__button-next" :class="{'disabled' : !isButtonEnable('buttonNext')}" :disabled="!isButtonEnable('buttonNext')">{{ options.labels.buttonNext }}</button>
                </template>
                <template v-else-if="currentStep == steps.findIndex((el) => el.target == '#proceed-payment')">
                  <button @click.prevent="skip" v-if="!isLast" class="v-step__button v-step__button-skip" :class="{'disabled' : !isButtonEnable('buttonSkip')}" :disabled="!isButtonEnable('buttonSkip')">{{ options.labels.buttonSkip }}</button>
                  <button @click.prevent="previousStep" v-if="!isFirst" class="v-step__button v-step__button-previous" :class="{'disabled' : !isButtonEnable('buttonPrevious')}" :disabled="!isButtonEnable('buttonPrevious')">{{ options.labels.buttonPrevious }}</button>
                </template>
                <template v-else-if="currentStep == steps.findIndex((el) => el.target == '#payment-method')">
                  <button @click.prevent="skip" v-if="!isLast" class="v-step__button v-step__button-skip" :class="{'disabled' : !isButtonEnable('buttonSkip')}" :disabled="!isButtonEnable('buttonSkip')">{{ options.labels.buttonSkip }}</button>
                  <button @click.prevent="$emit('previousPage');previousStep(200);" v-if="!isFirst" class="v-step__button v-step__button-previous" :class="{'disabled' : !isButtonEnable('buttonPrevious')}" :disabled="!isButtonEnable('buttonPrevious')">{{ options.labels.buttonPrevious }}</button>
                  <button @click.prevent="nextStep" v-if="!isLast" class="v-step__button v-step__button-next" :class="{'disabled' : !isButtonEnable('buttonNext')}" :disabled="!isButtonEnable('buttonNext')">{{ options.labels.buttonNext }}</button>
                </template>
                <!-- Checkout Page END -->
                <!-- Product Detail Page START -->
                <template v-else-if="currentStep == steps.findIndex((el) => /pick-color/.test(el.target)) || currentStep == steps.findIndex((el) => /pick-size/.test(el.target))">
                  <button @click.prevent="skip" v-if="!isLast" class="v-step__button v-step__button-skip" :class="{'disabled' : !isButtonEnable('buttonSkip')}" :disabled="!isButtonEnable('buttonSkip')">{{ options.labels.buttonSkip }}</button>
                  <button @click.prevent="previousStep" v-if="!isFirst" class="v-step__button v-step__button-previous" :class="{'disabled' : !isButtonEnable('buttonPrevious')}" :disabled="!isButtonEnable('buttonPrevious')">{{ options.labels.buttonPrevious }}</button>
                </template>
                <!-- Product Detail Page END -->
                <!-- Universal -->
                <template v-else>
                  <button @click.prevent="skip" v-if="!isLast" class="v-step__button v-step__button-skip" :class="{'disabled' : !isButtonEnable('buttonSkip')}" :disabled="!isButtonEnable('buttonSkip')">{{ options.labels.buttonSkip }}</button>
                  <button @click.prevent="previousStep" v-if="!isFirst" class="v-step__button v-step__button-previous" :class="{'disabled' : !isButtonEnable('buttonPrevious')}" :disabled="!isButtonEnable('buttonPrevious')">{{ options.labels.buttonPrevious }}</button>
                  <button @click.prevent="nextStep" v-if="!isLast" class="v-step__button v-step__button-next" :class="{'disabled' : !isButtonEnable('buttonNext')}" :disabled="!isButtonEnable('buttonNext')">{{ options.labels.buttonNext }}</button>
                  <button @click.prevent="finish" v-if="isLast" class="v-step__button v-step__button-stop" :class="{'disabled' : !isButtonEnable('buttonStop')}" :disabled="!isButtonEnable('buttonStop')">{{ options.labels.buttonStop }}</button>
                </template>
              </div>
            </div>
          </v-step>
        </template>
      </transition> 
    </template>
  </v-tour>
</template>

<script>
import { tourSettings } from "@/_helpers";

export default {
  name: "VueTour",
  props:["tourName", "steps", "callbacks", "header", "content", "actions"],
  data() { 
    return {
      options: tourSettings
    }
  },
  computed: {
    numberOfSteps () {
      return this.steps.length
    },
    currentStep() {
      return this.$tours[this.tourName].currentStep
    },
    isFirst () {
      return this.currentStep === 0
    },
    isLast () {
      return this.currentStep === this.steps.length - 1
    },
    enableButton () {
      return this.steps[this.currentStep].params.enableButton
    }
  },
  mounted() {
    window.onpopstate = () => {
      this.skip();
    };
  },
  methods: {
    startTour(timeOut = 0) {
      setTimeout(() => {
        this.$tours[this.tourName].start()
      }, timeOut);
    },
    nextStep(timeOut = 0) {
      setTimeout(() => {
        this.$tours[this.tourName].nextStep()
      }, timeOut);
    },
    previousStep(timeOut = 0) {
      setTimeout(() => {
        this.$tours[this.tourName].previousStep()
      }, timeOut);
    },
    skip() {
      this.$tours[this.tourName].skip()
    },
    finish() {
      this.$tours[this.tourName].finish()
    },
    // Return true if the tour is active, which means that there's a VStep displayed
    isRunning () {
      return this.currentStep > -1 && this.currentStep < this.numberOfSteps
    },
    
    setButtonStatus(id, button, status) {
      const index = this.steps.findIndex((el) => el.target == id)
      this.steps[index].params.enableButton[button] = status
    },
    // Return button status by button type (skip, next, previous, stop)
    isButtonEnable(name) {
      return this.steps[this.currentStep].params.enableButton[name]
    }
  } 
};
</script>

<style>
.fade-enter-active,.fade-leave-active {
  -webkit-transition:opacity .5s;
  transition:opacity .5s
}
.fade-enter,.fade-leave-to{
  opacity:0
}

/* Custom Styling */
.v-step {
	background: #352e2a !important;
	border-radius: 10px !important;
	z-index: 1000 !important;
  padding: 1rem 1.25rem !important;
}

.v-step__header {
  margin: 0 !important;
  padding: 0 8px 8px !important;
	background-color: #352e2a !important;
  font-weight: bold;
  word-wrap: break-word;
}

.v-tour__target--highlighted {
  /* box-shadow: 0 0 0 99999px rgba(0,0,0,.4); */
  z-index: 1000 !important;
}


:is(#chat-product).v-tour__target--highlighted {
  pointer-events: none;
}

.side-menu > .v-tour__target--highlighted {
  pointer-events: none;
}

.v-step__button {
  border-radius: 4px !important;
  font-size: 14px !important;
  padding: 0px 14px !important;
}

.v-step__button:hover {
  background: #d89700 !important;
  color: #fff !important;
  border-color: #d89700 !important;
}

.v-step__button.disabled {
  opacity: 0.65;
  cursor: not-allowed;
}

.v-step__arrow--dark:before {
	background: #352e2a !important;
}
</style>
